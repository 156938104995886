
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import AlreadyRegistered from '../components/alreadyregistered.vue';
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import insertPromoCodeModal from '../modals/inserPromoModals/insertPromoCodeModal.vue';
import promoErrorModal from '../modals/inserPromoModals/steps/promoErrorModal.vue';
import promoSuccessModal from '../modals/inserPromoModals/steps/promoSuccessModal.vue';

@Options({
    components: {
        AlreadyRegistered
    }
})
export default class SelectLanguage extends Vue {

    address: OM.Address = new OM.Address();
    languageSelected: string = "";
    list: OM.TextIdentifier[] = [];

    created() {
        this.languageSelected = store.state.loggedUser.preferredCulture;
        this.address = store.state.currentPosition;
        this.list = this.$localizationService.getEnabledLanguageList();
    }

    setLanguage(){
        var loggedUser = store.state.loggedUser;
        loggedUser.preferredCulture = this.languageSelected;

        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

    next() {
       this.setLanguage();

        this.$router.push("/shareposition")
    }
}
